import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";

const Spoilage = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/spoilages_total');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [3, 4, 5]
                                }

                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })

                        var api = window.$('.dt-tbl').DataTable();
                        var total_qty = 0;
                        var total_amount = 0;
                        new window.$.fn.dataTable.FixedHeader(api);

                        var totalqty = api.column(3, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                        var totalamount = api.column(5, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                        $('#total_amount').html(`${commaNumber(parseInt(total_amount))} د.ع`);

                        window.$('.dt-tbl').DataTable().on('search.dt', function () {
                            var total_qty = 0;
                            var total_amount = 0;

                            var totalqty = api.column(3, { search: 'applied' }).data()
                            totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                            var totalamount = api.column(5, { search: 'applied' }).data()
                            totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                            $('#total_qty').html(`${commaNumber(total_qty.toFixed(2))} كغ`);
                            $('#total_amount').html(`${commaNumber(parseInt(total_amount))} د.ع`);

                        });
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [product, setProduct] = useState([]);
    const getProduct = async () => {
        const { data } = await axios.get('/product_balance');
        setProduct(
            data.map((item) => {
                return {
                    value: item.id,
                    label: `${item.product_name} - كمية الموجودة: ${commaNumber(item.balance_qty)} كغ`,
                };
            })
        );
    }
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        getData();
        getProduct();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);



    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">التالفات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة التلف</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2"> تلف جديد</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> تلف جديد</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    if (submit) {
                                                        axios.post(`/spoilages`, data).then(res => {
                                                            if (res.data !== 'done') {
                                                                toast.error(res.data, {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            } else {
                                                                getData();
                                                                toast.success('تمت الاضافة بنجاح');
                                                                e.target.reset();
                                                                window.$('#new').modal('hide');
                                                            }
                                                        });
                                                    } else {
                                                        toast.error('الكمية المطلوبة اكبر من الكمية المتاحة', {
                                                            position: "top-right",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    }
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="product_id" className="form-label">المادة</label>
                                                                    <Select options={product} name="product_id" placeholder="اختر المادة" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label htmlFor="qty" className="form-label">الكمية</label>
                                                                    <input type="number" className="form-control" step={"any"} name="qty" placeholder="الكمية" required onChange={(e) => {
                                                                        const balance_qty = product.find(item => item.value === parseInt(e.target.form.product_id.value)).label.split(':')[1].split(' ')[1];
                                                                        const balance_qty_arr = balance_qty.split(',');
                                                                        let balance_qty_str = '';
                                                                        balance_qty_arr.forEach(item => {
                                                                            balance_qty_str += item;
                                                                        });
                                                                        if (parseInt(e.target.value) > parseInt(balance_qty_str)) {
                                                                            toast.error('الكمية المدخلة اكبر من الكمية الموجودة');
                                                                            setSubmit(false);
                                                                        } else {
                                                                            e.target.value = e.target.value;
                                                                            setSubmit(true);
                                                                        }
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="mb-3">
                                                                    <label htmlFor="note" className="form-label">ملاحظة</label>
                                                                    <input type="text" className="form-control" name="note" placeholder="ملاحظة" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                        <button type="submit" className="btn btn-primary"> اضافة</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>المادة</th>
                                        <th>السعر</th>
                                        <th>الكمية</th>
                                        <th>سعر المتوسط</th>
                                        <th>مجموع المبلغ</th>
                                        <th>سجل التلف</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => (
                                        <tr key={item.product_id}>
                                            <td>{index + 1}</td>
                                            <td> <Link to={`/spoilage/${item.product_id}`} className="text-decoration-none">{item.product_name}</Link> </td>
                                            <td>{commaNumber(parseInt(item.price))} {item.currency}</td>
                                            <td>{commaNumber(item.qty)} كغ</td>
                                            <td>{commaNumber(parseInt(item.price))} {item.currency}</td>
                                            <td>{commaNumber(parseInt(item.total))} {item.currency}</td>
                                            <td>
                                                <Link to={`/spoilage/${item.product_id}`} className="badge bg-primary" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-repeat"><polyline points="17 1 21 5 17 9" /><path d="M3 11V9a4 4 0 0 1 4-4h14" /><polyline points="7 23 3 19 7 15" /><path d="M21 13v2a4 4 0 0 1-4 4H3" /></svg>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع الوزن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 كغ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">مجموع المبلغ</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 د.ع </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Spoilage;