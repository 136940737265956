import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';

const MainParts = ({ children }) => {
    return (
        <div className="main-wrapper">
            <Sidebar />
            <div className="page-wrapper">
                <Navbar />
                {children}
                <Footer />
            </div>
        </div>
    );
};

export default MainParts;
