import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    function _login(e) {
        e.preventDefault();
        let username = e.target.username.value;
        let password = e.target.password.value;
        axios.post('/login', { username, password }).then((res) => {
            if (res.data === "err") {
                toast.error("اسم مستخدم أو كلمة مرور غير صحيحة", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                navigate('/dashboard')
            }
        })
    }

    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                navigate('/dashboard')
            }
        });
    }, [])

    return (
        <div className="main-wrapper">
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <div className="row w-100 mx-0 auth-page">
                        <div className="col-md-8 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-4 pe-md-0">
                                        <div className="auth-side-wrapper">
                                        </div>
                                    </div>
                                    <div className="col-md-8 ps-md-0">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <a href="/" className="noble-ui-logo d-block mb-2">IMS<span> نظام إدارة المخزون</span></a>
                                            <h5 className="text-muted fw-normal mb-4">مرحبًا بعودتك! قم بتسجيل الدخول إلى حسابك.</h5>
                                            <form className="forms-sample" onSubmit={_login}>
                                                <div className="mb-3">
                                                    <label htmlFor="userEmail" className="form-label">اسم مستخدم</label>
                                                    <input type="username" className="form-control" id="username" placeholder="اكتب اسم مستخدم هنا" />
                                                    <small className="form-text text-muted"><span className="alphabet-alert" style={{ color: 'red' }}></span></small>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="userPassword" className="form-label">كلمة المرور</label>
                                                    <input type="password" className="form-control" id="password" autoComplete="current-password" placeholder="اكتب كلمة المرور هنا" />
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0">
                                                        تسجيل الدخول
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 small ltr">
                                        <p class="text-muted mb-1 mb-md-0 tx-13">© 2023 - {new Date().getFullYear()} <a href="/">IMS</a> حل تجاري لإدارة المخزون بكفاءة</p>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;