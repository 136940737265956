import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from './Components/Pages/404';
import Dashboard from './Components/Pages/Dashboard';
import Clients from './Components/Accounts/Clients';
import MainParts from './Components/Layout/MainParts';
import Users from './Components/Accounts/Users';
import Products from './Components/Items/Products';
import Fund from './Components/Fund/Fund';
import FundBy from './Components/Fund/FundBy';
import Purchases from './Components/Purchases/Purchases';
import Sales from './Components/Sales/Sales';
import Spoilage from './Components/Spoilage/Spoilage';
import SpoilageBy from './Components/Spoilage/SpoilageBy';
import ClientBalance from './Components/Report/ClientBalance';
import Revenus from './Components/Report/Revenus';
import Transactions from './Components/Report/Transaction';
import Profile from './Components/Accounts/Profile';
import Login from './Components/Pages/Login';

const AppRoutes = createBrowserRouter(
    [
        {
            path: "/",
            element: <Login />,
            errorElement: <ErrorPage />,
        },
        {
            path: "/dashboard",
            element: <MainParts><Dashboard /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/user",
            element: <MainParts><Users /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/client",
            element: <MainParts><Clients /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/profile/:id",
            element: <MainParts><Profile /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/product",
            element: <MainParts><Products /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/fund",
            element: <MainParts><Fund /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/fund/:id",
            element: <MainParts><FundBy /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/purchase",
            element: <MainParts><Purchases /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/sale",
            element: <MainParts><Sales /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/sale",
            element: <MainParts><Sales /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/spoilage",
            element: <MainParts><Spoilage /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/spoilage/:id",
            element: <MainParts><SpoilageBy /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/client/balance",
            element: <MainParts><ClientBalance /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/transaction",
            element: <MainParts><Transactions /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/revenu",
            element: <MainParts><Revenus /></MainParts>,
            errorElement: <ErrorPage />,
        },
    ]
);

export default AppRoutes;
